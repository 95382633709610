import React from 'react'
import { FaPlusCircle } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'

function SideBar() {
  return (
    <>

<nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
      <div className="position-sticky pt-3">
        <ul className="nav flex-column">
          <li className="nav-item">
            <NavLink className="nav-link active" aria-current="page" to="/">
              {/* <span data-feather="home"></span> */}
              Dashboard
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="#">
              {/* <span data-feather="file"></span> */}
              Project A
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="#">
              {/* <span data-feather="shopping-cart"></span> */}
              Project B
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="#">
              {/* <span data-feather="users"></span> */}
              Project C
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="#">
              {/* <span data-feather="bar-chart-2"></span> */}
              Project D
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="#">
              {/* <span data-feather="layers"></span> */}
              Project E
            </NavLink>
          </li>
        </ul>

        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
          <span>Integration</span>
          <NavLink className="link-secondary" to="#" aria-label="Add a new report">
          <FaPlusCircle />
          </NavLink>
        </h6>
        <ul className="nav flex-column mb-2">
        <li className="nav-item">
            <NavLink className="nav-link" to="/plan-file">
              {/* <span data-feather="file-text"></span> */}
              Plan File
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/current-data">
              {/* <span data-feather="file-text"></span> */}
              Current Data
            </NavLink>
          </li>
        
          <li className="nav-item">
            <NavLink className="nav-link" to="#">
              {/* <span data-feather="file-text"></span> */}
              Social engagement
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="#">
              {/* <span data-feather="file-text"></span> */}
              Year-end sale
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>

    </>
  )
}

export default SideBar