import React, { useState } from "react";
import axios from "axios";

const PlanFileUpload = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  const onFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      alert("Please select a file before uploading.");
      return;
    }

    setUploading(true);

    const formData = new FormData();
    formData.append("file", file);

    try {
      await axios.post("https://powerbi.optiwisesolutions.com/api/plan", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      alert("File uploaded successfully");
    } catch (error) {
      console.error("Error uploading file:", error.message);
      alert("Error uploading file. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="mt-5">
      <h2>File Upload</h2>
      <form onSubmit={onFormSubmit}>
        <input type="file" onChange={onFileChange} />
        {file && <p>Selected file: {file.name}</p>}
        <button type="submit" disabled={uploading}>
          {uploading ? "Uploading..." : "Upload"}
        </button>
      </form>
    </div>
  );
};

export default PlanFileUpload;
