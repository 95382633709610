import React from 'react'

const About = () => {
  return (
    <>
    
    <h1>Umair Fayyaz</h1>
    
    </>
  )
}

export default About