import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Chart } from 'chart.js/auto';
import axios from 'axios';

const PlanGraph = () => {
  const [chartPlanData, setChartPlanData] = useState([]);
  const [chartCurrentData, setChartCurrentData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const chartRef = useRef(null);

  const destroyChart = () => {
    if (chartRef.current && chartRef.current.chart) {
      chartRef.current.chart.destroy();
    }
  };

  const createChart = useCallback((label, property, xAxisProperty, backgroundColor) => {
    if (chartRef.current && chartRef.current.getContext) {
      const ctx = chartRef.current.getContext('2d');

      // Destroy the previous chart if it exists
      destroyChart();

      // Extract unique labels from the plan data
      const uniqueLabelsPlan = [...new Set(chartPlanData.map((item) => item[xAxisProperty]))];

      // Extract unique labels from the current data
      const uniqueLabelsCurrent = [...new Set(chartCurrentData.map((item) => item[xAxisProperty]))];

      // Find common labels between plan and current data
      const commonLabels = uniqueLabelsPlan.filter(label => uniqueLabelsCurrent.includes(label));

      // Calculate the difference in values for common labels
      const differenceValues = commonLabels.map((label) => {
        const planItem = chartPlanData.find((item) => item[xAxisProperty] === label);
        const currentItem = chartCurrentData.find((item) => item[xAxisProperty] === label);

        const planValue = planItem ? Number(planItem[property]) : 0;
        const currentValue = currentItem ? Number(currentItem[property]) : 0;

        return currentValue - planValue;
      });

      // Determine the color for the Difference dataset based on the sign of the difference
      const differenceColors = differenceValues.map((value) => (value >= 0 ? '#008000' : '#FF0000'));

      // Create a new chart
      chartRef.current.chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: commonLabels,
          datasets: [
            {
              label: `Plan ${label}`,
              data: commonLabels.map((label) => {
                const item = chartPlanData.find((item) => item[xAxisProperty] === label);
                return item ? Number(item[property]) : 0;
              }),
              backgroundColor,
              borderColor: backgroundColor,
              borderWidth: 1,
            },
            {
              label: `Current ${label}`,
              data: commonLabels.map((label) => {
                const item = chartCurrentData.find((item) => item[xAxisProperty] === label);
                return item ? Number(item[property]) : 0;
              }),
              backgroundColor: '#FF8300',
              borderColor: '#FF8300',
              borderWidth: 1,
            },
            {
              label: `Difference ${label}`,
              data: differenceValues,
              backgroundColor: differenceColors,
              borderColor: differenceColors,
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: [
              {
                type: 'linear',
                position: 'bottom',
              },
            ],
            y: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          plugins: {
            legend: {
              labels: {
                color: '#000',
              },
            },
          },
        },
      });
    }
  }, [chartPlanData, chartCurrentData]);

  useEffect(() => {
    // Fetch plan data and update chartPlanData state
    axios.get('https://powerbi.optiwisesolutions.com/api/plan-data')
      .then((response) => {
        if (response.data && Array.isArray(response.data.data)) {
          setChartPlanData(response.data.data);
        } else {
          setError('Invalid plan data format');
        }
      })
      .catch((error) => {
        console.error('Error fetching plan data:', error);
        setError('Error fetching plan data');
      })
      .finally(() => {
        setLoading(false);
      });

    // Fetch current data and update chartCurrentData state
    axios.get('https://powerbi.optiwisesolutions.com/api/current-data')
      .then((response) => {
        if (response.data && Array.isArray(response.data.data)) {
          setChartCurrentData(response.data.data);
        } else {
          setError('Invalid current data format');
        }
      })
      .catch((error) => {
        console.error('Error fetching current data:', error);
        setError('Error fetching current data');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    // Create or update the chart when data changes
    createChart('Data', 'Sale_amt', 'Item', ['#1961ae']);
    // createChart('Sale', 'Sale_amt', 'Item', ['#1961ae', '#ef6a00', '#f2cd00', '#79c300', '#1961ae', '#61007d']);
  }, [createChart]);

  return (
    <div className="container">
      <div className="row">
        <h2>Plan and Current Data</h2>
        {error ? (
          <p>Error: {error}</p>
        ) : loading ? (
          <p>Loading...</p>
        ) : (
          <div className="col-12">
            <canvas ref={chartRef} width={400} height={400} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PlanGraph;
