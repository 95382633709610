import React from 'react';
import Header from '../component/Header';
import SideBar from '../component/SideBar';
import { Outlet } from 'react-router-dom';

const Home = () => {
  return (
    <>
      
        <Header />
        <div className="container-fluid">
          <div className="row">
            <SideBar />
            <Outlet />
          </div>
        </div>
      
    </>
  );
};

export default Home;
