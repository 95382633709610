import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./assets/css/dashboard.css";
import "./assets/css/dashboard.rtl.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import HomeContent from "./component/HomeContent";
import CurrentData from "./pages/CurrentData";
import About from "./pages/About";
import PlanFile from "./pages/PlanFile";

function App() {
  return (
    <>
       {/* <Router basename="/frontend">  */}
       <Router >
      
        <Routes>
          <Route path="/" element={<Home />}>
            <Route index element={<HomeContent />} />
            {/* Add other nested routes if needed */}
            <Route path="/current-data" element={<CurrentData />}></Route>
            <Route path="/plan-file" element={<PlanFile />}></Route>
            <Route path="/about" element={<About />}></Route>
          </Route>
        </Routes>
      </Router>

    </>
  );
}

export default App;


