import React from "react";
import { FaCalendarAlt } from "react-icons/fa";
// import CurrentGraph from "./CurrentGraph";
import PlanGraph from "./PlanGraph";
// import DifferenceGraph from "./DifferenceGraph";

const HomeContent = () => {
  return (
    <>
      <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">Dashboard</h1>
          <div className="btn-toolbar mb-2 mb-md-0">
            <div className="btn-group me-2">
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary"
              >
                Share
              </button>
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary"
              >
                Export
              </button>
            </div>
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary dropdown-toggle"
            >
              <FaCalendarAlt />
              This week
            </button>
          </div>
        </div>
        <div className="row">

        <div className="col-12">
        <PlanGraph />
        </div>
{/* 
        <div className="col-4">
        <CurrentGraph />
        </div>

        <div className="col-4">
        <DifferenceGraph />
        </div> */}

       

        </div>

   
      </main>
    </>
  );
};

export default HomeContent;
